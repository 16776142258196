import Icon from 'components/dist/atoms/Icon';
import Head from 'next/head';
import type { FC } from 'react';
import { getAssetPath } from 'src/utils/url/get-asset-path';

export const SplashScreen: FC = () => (
  <>
    <Head>
      <title>
        Loading...
      </title>
    </Head>
    <div
      className='p-6  inset-0 fixed w-screen bg-black-primary flex h-dvh items-center flex-col justify-center z-splash'

    >
      <div
        className='h9'>
        <img
          src={getAssetPath('static/232px_DarkMode.png')}
          alt="mysherpas-logo"
          width='166'
          height='36'
        />
      </div>
      <div className='mt-4'>
        <Icon name="Refresh" width={42} height={42} className="animate-spin text-white [&_path:last-child]:hidden" />
      </div>
    </div>
  </>
);
