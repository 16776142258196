import * as p from "react";
import { useState as _ } from "react";
import { a as M, _ as N } from "./tslib.es6-CR4a2p76.js";
var T = function(e) {
  if (typeof document > "u")
    return null;
  var t = Array.isArray(e) ? e[0] : e;
  return t.ownerDocument.body;
}, d = /* @__PURE__ */ new WeakMap(), g = /* @__PURE__ */ new WeakMap(), m = {}, b = 0, W = function(e) {
  return e && (e.host || W(e.parentNode));
}, I = function(e, t) {
  return t.map(function(n) {
    if (e.contains(n))
      return n;
    var r = W(n);
    return r && e.contains(r) ? r : (console.error("aria-hidden", n, "in not contained inside", e, ". Doing nothing"), null);
  }).filter(function(n) {
    return !!n;
  });
}, z = function(e, t, n, r) {
  var a = I(t, Array.isArray(e) ? e : [e]);
  m[n] || (m[n] = /* @__PURE__ */ new WeakMap());
  var u = m[n], c = [], i = /* @__PURE__ */ new Set(), y = new Set(a), v = function(o) {
    !o || i.has(o) || (i.add(o), v(o.parentNode));
  };
  a.forEach(v);
  var s = function(o) {
    !o || y.has(o) || Array.prototype.forEach.call(o.children, function(f) {
      if (i.has(f))
        s(f);
      else {
        var h = f.getAttribute(r), C = h !== null && h !== "false", E = (d.get(f) || 0) + 1, x = (u.get(f) || 0) + 1;
        d.set(f, E), u.set(f, x), c.push(f), E === 1 && C && g.set(f, !0), x === 1 && f.setAttribute(n, "true"), C || f.setAttribute(r, "true");
      }
    });
  };
  return s(t), i.clear(), b++, function() {
    c.forEach(function(o) {
      var f = d.get(o) - 1, h = u.get(o) - 1;
      d.set(o, f), u.set(o, h), f || (g.has(o) || o.removeAttribute(r), g.delete(o)), h || o.removeAttribute(n);
    }), b--, b || (d = /* @__PURE__ */ new WeakMap(), d = /* @__PURE__ */ new WeakMap(), g = /* @__PURE__ */ new WeakMap(), m = {});
  };
}, te = function(e, t, n) {
  n === void 0 && (n = "data-aria-hidden");
  var r = Array.from(Array.isArray(e) ? e : [e]), a = T(e);
  return a ? (r.push.apply(r, Array.from(a.querySelectorAll("[aria-live]"))), z(r, a, n, "aria-hidden")) : function() {
    return null;
  };
}, w = "right-scroll-bar-position", S = "width-before-scroll-bar", B = "with-scroll-bars-hidden", D = "--removed-body-scroll-bar-size";
function L(e, t) {
  return typeof e == "function" ? e(t) : e && (e.current = t), e;
}
function O(e, t) {
  var n = _(function() {
    return {
      // value
      value: e,
      // last callback
      callback: t,
      // "memoized" public interface
      facade: {
        get current() {
          return n.value;
        },
        set current(r) {
          var a = n.value;
          a !== r && (n.value = r, n.callback(r, a));
        }
      }
    };
  })[0];
  return n.callback = t, n.facade;
}
function re(e, t) {
  return O(null, function(n) {
    return e.forEach(function(r) {
      return L(r, n);
    });
  });
}
function P(e) {
  return e;
}
function j(e, t) {
  t === void 0 && (t = P);
  var n = [], r = !1, a = {
    read: function() {
      if (r)
        throw new Error("Sidecar: could not `read` from an `assigned` medium. `read` could be used only with `useMedium`.");
      return n.length ? n[n.length - 1] : e;
    },
    useMedium: function(u) {
      var c = t(u, r);
      return n.push(c), function() {
        n = n.filter(function(i) {
          return i !== c;
        });
      };
    },
    assignSyncMedium: function(u) {
      for (r = !0; n.length; ) {
        var c = n;
        n = [], c.forEach(u);
      }
      n = {
        push: function(i) {
          return u(i);
        },
        filter: function() {
          return n;
        }
      };
    },
    assignMedium: function(u) {
      r = !0;
      var c = [];
      if (n.length) {
        var i = n;
        n = [], i.forEach(u), c = n;
      }
      var y = function() {
        var s = c;
        c = [], s.forEach(u);
      }, v = function() {
        return Promise.resolve().then(y);
      };
      v(), n = {
        push: function(s) {
          c.push(s), v();
        },
        filter: function(s) {
          return c = c.filter(s), n;
        }
      };
    }
  };
  return a;
}
function ae(e) {
  e === void 0 && (e = {});
  var t = j(null);
  return t.options = M({ async: !0, ssr: !1 }, e), t;
}
var R = function(e) {
  var t = e.sideCar, n = N(e, ["sideCar"]);
  if (!t)
    throw new Error("Sidecar: please provide `sideCar` property to import the right car");
  var r = t.read();
  if (!r)
    throw new Error("Sidecar medium not found");
  return p.createElement(r, M({}, n));
};
R.isSideCarExport = !0;
function ie(e, t) {
  return e.useMedium(t), R;
}
var G = function() {
  if (typeof __webpack_nonce__ < "u")
    return __webpack_nonce__;
};
function Q() {
  if (!document)
    return null;
  var e = document.createElement("style");
  e.type = "text/css";
  var t = G();
  return t && e.setAttribute("nonce", t), e;
}
function V(e, t) {
  e.styleSheet ? e.styleSheet.cssText = t : e.appendChild(document.createTextNode(t));
}
function q(e) {
  var t = document.head || document.getElementsByTagName("head")[0];
  t.appendChild(e);
}
var F = function() {
  var e = 0, t = null;
  return {
    add: function(n) {
      e == 0 && (t = Q()) && (V(t, n), q(t)), e++;
    },
    remove: function() {
      e--, !e && t && (t.parentNode && t.parentNode.removeChild(t), t = null);
    }
  };
}, H = function() {
  var e = F();
  return function(t, n) {
    p.useEffect(function() {
      return e.add(t), function() {
        e.remove();
      };
    }, [t && n]);
  };
}, K = function() {
  var e = H(), t = function(n) {
    var r = n.styles, a = n.dynamic;
    return e(r, a), null;
  };
  return t;
}, U = {
  left: 0,
  top: 0,
  right: 0,
  gap: 0
}, A = function(e) {
  return parseInt(e || "", 10) || 0;
}, J = function(e) {
  var t = window.getComputedStyle(document.body), n = t[e === "padding" ? "paddingLeft" : "marginLeft"], r = t[e === "padding" ? "paddingTop" : "marginTop"], a = t[e === "padding" ? "paddingRight" : "marginRight"];
  return [A(n), A(r), A(a)];
}, X = function(e) {
  if (e === void 0 && (e = "margin"), typeof window > "u")
    return U;
  var t = J(e), n = document.documentElement.clientWidth, r = window.innerWidth;
  return {
    left: t[0],
    top: t[1],
    right: t[2],
    gap: Math.max(0, r - n + t[2] - t[0])
  };
}, Y = K(), l = "data-scroll-locked", Z = function(e, t, n, r) {
  var a = e.left, u = e.top, c = e.right, i = e.gap;
  return n === void 0 && (n = "margin"), `
  .`.concat(B, ` {
   overflow: hidden `).concat(r, `;
   padding-right: `).concat(i, "px ").concat(r, `;
  }
  body[`).concat(l, `] {
    overflow: hidden `).concat(r, `;
    overscroll-behavior: contain;
    `).concat([
    t && "position: relative ".concat(r, ";"),
    n === "margin" && `
    padding-left: `.concat(a, `px;
    padding-top: `).concat(u, `px;
    padding-right: `).concat(c, `px;
    margin-left:0;
    margin-top:0;
    margin-right: `).concat(i, "px ").concat(r, `;
    `),
    n === "padding" && "padding-right: ".concat(i, "px ").concat(r, ";")
  ].filter(Boolean).join(""), `
  }
  
  .`).concat(w, ` {
    right: `).concat(i, "px ").concat(r, `;
  }
  
  .`).concat(S, ` {
    margin-right: `).concat(i, "px ").concat(r, `;
  }
  
  .`).concat(w, " .").concat(w, ` {
    right: 0 `).concat(r, `;
  }
  
  .`).concat(S, " .").concat(S, ` {
    margin-right: 0 `).concat(r, `;
  }
  
  body[`).concat(l, `] {
    `).concat(D, ": ").concat(i, `px;
  }
`);
}, k = function() {
  var e = parseInt(document.body.getAttribute(l) || "0", 10);
  return isFinite(e) ? e : 0;
}, $ = function() {
  p.useEffect(function() {
    return document.body.setAttribute(l, (k() + 1).toString()), function() {
      var e = k() - 1;
      e <= 0 ? document.body.removeAttribute(l) : document.body.setAttribute(l, e.toString());
    };
  }, []);
}, oe = function(e) {
  var t = e.noRelative, n = e.noImportant, r = e.gapMode, a = r === void 0 ? "margin" : r;
  $();
  var u = p.useMemo(function() {
    return X(a);
  }, [a]);
  return p.createElement(Y, { styles: Z(u, !t, a, n ? "" : "!important") });
};
export {
  oe as R,
  ae as c,
  ie as e,
  S as f,
  te as h,
  K as s,
  re as u,
  w as z
};
