export const getPrettyCountdownTime = (count: number) => {
    const hours = Math.floor(count / 3600);
    const minutes = Math.floor((count % 3600) / 60);
    const remainingSeconds = count % 60;

    if (hours > 0) {
        return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    } else {
        return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }
}

export const getMaxUnitOfTime = (count: number) => {
    if (count >= 3600) {
        return 'hours';
    } else {
        return 'minutes';
    }
}